// src/utils/trpc.ts
import { createTRPCClient, httpBatchLink, loggerLink } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';
import { ssrPrepass } from '@trpc/next/ssrPrepass';
import { env } from 'env.mjs';
import { NextPageContext } from 'next';
import superjson from 'superjson';

import { getBaseUrl } from './shared';
import type { AppRouter } from 'server/routers';

export { type RouterInputs, type RouterOutputs } from './shared';

/**
 * Extend `NextPageContext` with meta data that can be picked up by `responseMeta()` when server-side rendering
 */
export interface SSRContext extends NextPageContext {
  /**
   * Set HTTP Status code
   * @example
   * const utils = trpc.useContext();
   * if (utils.ssrContext) {
   *   utils.ssrContext.status = 404;
   * }
   */
  status?: number;
}

/**
 * A tRPC client instance for making API calls outside of React components.
 * This client is useful for calling tRPC procedures in helper functions, services, or utility files.
 * Note: This is a client-side instance and should not be used in server-side code.
 * @link https://trpc.io/docs/client/vanilla
 */
export const trpcVanillaClient = createTRPCClient<AppRouter>({
  links: [
    httpBatchLink({
      url: `${getBaseUrl()}/api/trpc`,
      maxURLLength: 2048,
      transformer: superjson,
    }),
    loggerLink({
      enabled: (opts) => {
        return (
          (env.NEXT_PUBLIC_NODE_ENV === 'development' &&
            typeof window !== 'undefined') ||
          (opts.direction === 'down' && opts.result instanceof Error)
        );
      },
    }),
  ],
});

/**
 * The helper to access the TRPC API from the Pages Directory.
 */
export const api = createTRPCNext<AppRouter, SSRContext>({
  config({ ctx }) {
    return {
      /**
       * @link https://trpc.io/docs/links
       */
      links: [
        // adds pretty logs to your console in development and logs errors in production
        loggerLink({
          enabled: (opts) => {
            return (
              (env.NEXT_PUBLIC_NODE_ENV === 'development' &&
                typeof window !== 'undefined') ||
              (opts.direction === 'down' && opts.result instanceof Error)
            );
          },
        }),
        httpBatchLink({
          url: `${getBaseUrl()}/api/trpc`,
          maxURLLength: 2048,
          /**
           * @link https://trpc.io/docs/data-transformers
           */
          transformer: superjson,
          /**
           * Set custom request headers on every request from tRPC
           * @link https://trpc.io/docs/ssr
           */
          headers() {
            if (ctx?.req) {
              // To use SSR properly, you need to forward the client's headers to the server
              // This is so you can pass through things like cookies when we're server-side rendering

              // If you're using Node 18, omit the "connection" header
              const {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                connection: _connection,
                ...headers
              } = ctx.req.headers;
              return {
                ...headers,
                // Optional: inform server that it's an SSR request
                'x-ssr': '1',
              };
            }
            return {};
          },
        }),
      ],
      /**
       * @link https://react-query.tanstack.com/reference/QueryClient
       */
      // queryClientConfig: { defaultOptions: { queries: { staleTime: 60 } } },
      queryClientConfig: {
        defaultOptions: {
          queries: {
            // If we are in dev ignore online status as we may be running local
            // infrastructure.
            ...(env.NEXT_PUBLIC_NODE_ENV === 'development' && {
              networkMode: 'always',
            }),
          },
        },
      },
    };
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: true,
  ssrPrepass,
  transformer: superjson,
  /**
   * Set headers or status code when doing SSR
   */
  responseMeta(opts) {
    const ctx = opts.ctx as SSRContext;

    if (ctx.status) {
      // If HTTP status set, propagate that
      return {
        status: ctx.status,
      };
    }

    const error = opts.clientErrors[0];
    if (error) {
      // Propagate http first error from API calls
      return {
        status: error.data?.httpStatus ?? 500,
      };
    }

    // for app caching with SSR see https://trpc.io/docs/caching
    return {};
  },
});
