import { red } from '@mui/material/colors';
import { env } from 'env.mjs';

import { colorOverrides } from './deploymentColors';

declare module '@mui/material/styles/createPalette' {
  interface ExtraPalette {
    trialType: {
      cognitive: string;
      physical: string;
      benchmark: string;
    };
    leaderLine: {
      cognitive: string;
      physical: string;
      default: string;
    };
    scatterChart: {
      primary: string;
      secondary: string;
      point: string;
    };
    typography: {
      secondary: string;
    };
    button: {
      primary: string;
      primaryText: string;
    };
    table: {
      headerBackground: string;
      headerText: string;
    };
    pagination: {
      background: {
        default: string;
        hover: string;
        selected: string;
      };
      text: string;
    };
    colorVariants: {
      light: string;
      dark: string;
    };
    accent: {
      main: string;
    };
  }

  interface Palette extends ExtraPalette {}
  interface PaletteOptions extends ExtraPalette {}
}

export const defaultColors = {
  primary: {
    main: '#24D86C',
  },
  background: { default: '#000' },
  secondary: {
    main: '#FFFFFF',
  },
  error: {
    main: red.A400,
  },
  success: {
    main: '#24D86C',
  },
  trialType: {
    cognitive: '#FFBA06',
    physical: '#0BEFF2',
    benchmark: '#B2B4B6',
  },
  leaderLine: {
    cognitive: '#FFBA06',
    physical: '#0BEFF2',
    default: '#D9D9D9',
  },
  scatterChart: {
    primary: '#DCDCDC',
    secondary: 'black',
    point: '#24D86C',
  },
  typography: {
    secondary: '#767676',
  },
  button: {
    primary: 'black',
    primaryText: 'white',
  },
  table: {
    headerBackground: 'black',
    headerText: 'white',
  },
  pagination: {
    background: {
      default: '#00000099',
      hover: '#000000CC',
      selected: 'black',
    },
    text: 'white',
  },
  colorVariants: {
    light: '#BDF3D3',
    dark: '#168241',
  },
  accent: {
    main: '#00B67F',
  },
};

export const getDeploymentTargetBasedColors = () => {
  if (env.NEXT_PUBLIC_DEPLOYMENT_TARGET === 'OCI') {
    return { ...defaultColors, ...colorOverrides.oci };
  }

  return defaultColors;
};
