// Note we should deconstruct falso here to reduce bundle size but for DX I haven't ...
import * as falso from '@ngneat/falso'; // https://ngneat.github.io/falso/
import { rest } from 'msw';

export const handlers = [
  // Example mock with inline defined data
  rest.get('*/api/dave', (req, res, ctx) => {
    return res(
      ctx.delay('real'),
      ctx.status(200),
      ctx.json({
        name: 'Dave',
        age: 30,
        green: true,
      })
    );
  }),

  // Example mock with an array of data returned using falso
  rest.get('*/api/daves', (req, res, ctx) => {
    return res(
      ctx.delay('real'),
      ctx.status(200),
      ctx.json(
        Array.from({ length: falso.randNumber({ min: 10, max: 100 }) }, () => ({
          name: falso.randFullName(),
          age: falso.randNumber({ min: 13, max: 100 }),
          car: falso.randVehicle(),
        }))
      )
    );
  }),
];
