import { Roboto_Condensed } from 'next/font/google';
import localFont from 'next/font/local';

import { isMAHD } from 'helpers/deploymentTarget';

const roboto = Roboto_Condensed({
  subsets: ['latin'],
  weight: ['300', '400', '700'],
  display: 'swap',
});

const brandon = localFont({ src: 'fonts/Brandon_bld.otf' });

export const robotoFontFamily = roboto.style.fontFamily;
export const brandonFontFamily = brandon.style.fontFamily;

const defaultFontFamily = robotoFontFamily;

export const performanceLabTypography = {
  fontFamily: defaultFontFamily,
};

// VERCEL FONTS
const neueMontrealBold = localFont({ src: 'fonts/PPNeueMontreal-Bold.otf' });
const neueMontrealBook = localFont({ src: 'fonts/PPNeueMontreal-Book.otf' });
const neueMontrealLight = localFont({ src: 'fonts/PPNeueMontreal-Light.otf' });
const neueMontrealThin = localFont({ src: 'fonts/PPNeueMontreal-Thin.otf' });
const neueMontrealMedium = localFont({
  src: 'fonts/PPNeueMontreal-Medium.otf',
});
const neueMontrealRegular = localFont({
  src: 'fonts/PPNeueMontreal-Regular.otf',
});
const neueMontrealSemiBold = localFont({
  src: 'fonts/PPNeueMontreal-SemiBold.otf',
});

// MAHD FONTS
const myriadProRegular = localFont({ src: 'fonts/MyriadPro-Regular.otf' });
const myriadProSemiBold = localFont({ src: 'fonts/MyriadPro-Semibold.otf' });
const myriadProBold = localFont({ src: 'fonts/MyriadPro-Bold.otf' });

// USED
export const {
  mainThemeRegularFontFamily,
  mainThemeBoldFontFamily,
  mainThemeMediumFontFamily,
} = isMAHD()
  ? {
      mainThemeRegularFontFamily: myriadProRegular.style.fontFamily,
      mainThemeBoldFontFamily: myriadProBold.style.fontFamily,
      mainThemeMediumFontFamily: myriadProSemiBold.style.fontFamily,
    }
  : {
      mainThemeRegularFontFamily: neueMontrealRegular.style.fontFamily,
      mainThemeBoldFontFamily: neueMontrealBold.style.fontFamily,
      mainThemeMediumFontFamily: neueMontrealMedium.style.fontFamily,
    };

// UNUSED
export const mainThemeSemiBoldFontFamily =
  neueMontrealSemiBold.style.fontFamily;
export const mainThemeBookFontFamily = neueMontrealBook.style.fontFamily;
export const mainThemeLightFontFamily = neueMontrealLight.style.fontFamily;
export const mainThemeThinFontFamily = neueMontrealThin.style.fontFamily;

const defaultMainFontFamily = mainThemeRegularFontFamily;

export const mainTypography = {
  fontFamily: defaultMainFontFamily,
  fontWeightRegular: '500',
};
