import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';
import DefaultProfileImage from 'public/images/global/defaultProfile.png';

import { getDeploymentTargetBasedColors } from './colors';
import {
  brandonFontFamily,
  mainThemeBoldFontFamily,
  mainThemeMediumFontFamily,
  mainThemeRegularFontFamily,
  mainTypography,
  performanceLabTypography,
  robotoFontFamily,
} from './typography';
import { getPerformanceLabPagePaths } from 'helpers/getPerformanceLabPages';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    metricCardTitle: true;
    h1: true;
    h2: true;
    h3: true;
    h4: false;
    h5: false;
    h6: false;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    error: true;
  }
}

// Create a theme instance.
// NOTE: If we switch to supporting light mode in react as well as dark mode, we
// will have to have a theme instance for each mode that is selected based on
// the switcher. This is because there are dark mode tweaks in this theme.
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    xxl: 1920,
  },
};
const spacing = 4;

export type ThemeMode = Theme['palette']['mode'];

const getPerformanceLabTheme = (mode: ThemeMode) => {
  const clubBasedColors = getDeploymentTargetBasedColors();

  const themeBasics = createTheme({
    breakpoints,
    typography: performanceLabTypography,
    spacing,
    palette: { mode, ...clubBasedColors },
  });

  return createTheme(themeBasics, {
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            '.MuiButtonBase-root, &.Mui-disabled': {
              pointerEvents: 'all',
            },
          },
        },
        variants: [
          {
            props: { variant: 'primary' },
            style: {
              color: 'white',
              backgroundColor: '#00CB8E',
              fontFamily: brandonFontFamily,
              lineHeight: 1,
              transition: 'all 0.3s ease-out',
              '&:hover': {
                color: 'white',
                backgroundColor: '#00B67F',
                borderColor: 'transparent',
              },
            },
          },
          {
            props: { variant: 'primary', size: 'small' },
            style: {
              padding: '9px 10px',
              fontSize: '13px',
              height: '31px',
            },
          },
          {
            props: { variant: 'primary', size: 'medium' },
            style: {
              padding: '11px 16px',
              fontSize: '14px',
              height: '36px',
            },
          },
          {
            props: { variant: 'primary', size: 'large' },
            style: {
              padding: '13px 22px',
              fontSize: '15px',
              height: '41px',
            },
          },

          {
            props: { variant: 'secondary' },
            style: {
              transition: 'all 0.3s ease-out',
              color: themeBasics.palette.mode === 'dark' ? 'white' : 'black',
              fontFamily: brandonFontFamily,
              borderRadius: '6px',
              border: `1px ${
                themeBasics.palette.mode === 'dark' ? 'white' : 'black'
              } solid`,
              boxShadow: 'none',
              lineHeight: 1,
              '&:hover': {
                color: 'white',
                backgroundColor: 'black',
                borderColor:
                  themeBasics.palette.mode === 'light'
                    ? 'transparent'
                    : 'white',
              },
            },
          },
          {
            props: { variant: 'secondary', size: 'small' },
            style: {
              padding: '8px 9px',
              fontSize: '13px',
              height: '31px',
            },
          },
          {
            props: { variant: 'secondary', size: 'medium' },
            style: {
              padding: '10px 15px',
              fontSize: '14px',
              height: '36px',
            },
          },
          {
            props: { variant: 'secondary', size: 'large' },
            style: {
              padding: '12px 21px',
              fontSize: '15px',
              height: '41px',
            },
          },
          {
            props: { variant: 'error' },
            style: {
              color: '#DC3545',
              fontFamily: brandonFontFamily,
              lineHeight: 1,
              transition: 'all 0.3s ease-out',
              '&:hover': {
                color: 'white',
                backgroundColor: '#DC3545',
                borderColor: 'transparent',
              },
            },
          },
          {
            props: { variant: 'error', size: 'small' },
            style: {
              padding: '9px 10px',
              fontSize: '13px',
              height: '31px',
            },
          },
          {
            props: { variant: 'error', size: 'medium' },
            style: {
              padding: '11px 16px',
              fontSize: '14px',
              height: '36px',
            },
          },
          {
            props: { variant: 'error', size: 'large' },
            style: {
              padding: '13px 22px',
              fontSize: '15px',
              height: '41px',
            },
          },
        ],
      },

      MuiStep: {
        styleOverrides: {
          root: {
            '& .MuiStepLabel-label': {
              color: themeBasics.palette.mode === 'dark' ? 'white' : 'black',
            },
          },
        },
      },

      MuiRadio: {
        styleOverrides: {
          root: {
            '&&.Mui-checked svg': {
              color: themeBasics.palette.primary.main + '!important',
            },
          },
        },
      },

      MuiSelect: {
        styleOverrides: {
          inputRoot: {
            backgroundColor:
              themeBasics.palette.mode === 'dark' ? '#091521' : '#FFFFFF',
          },
          root: {
            '& .MuiSelect-iconOutlined, .MuiSvgIcon-root': {
              color: themeBasics.palette.primary.main,
            },
          },
        },
      },

      MuiAvatar: {
        styleOverrides: {
          root: {
            height: '48px',
            width: '48px',
            'svg.MuiAvatar-fallback': {
              width: '100%',
              height: '100%',
              background: `url(${DefaultProfileImage.src}) center no-repeat`,
              backgroundSize: '100% 100%',
            },
            'svg.MuiAvatar-fallback path': {
              display: 'none',
            },
          },
        },
      },

      MuiPaper: {
        styleOverrides: {
          root: {
            '& .Mui-disabled': {
              // TODO: remove my important when Zak removes
              // body.dark important statement
              color: `${themeBasics.palette.grey[600]} !important`,
            },
          },
        },
      },

      MuiCssBaseline: {
        styleOverrides: (theme) => ({
          body: {
            background: '#F5F7F9',
            color: theme.palette.mode === 'dark' ? 'white' : '#595959',
            margin: 0,
            fontFamily: `${robotoFontFamily} !important`,
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: 1.5,
            display: 'block',
            backgroundImage:
              theme.palette.mode === 'dark'
                ? 'url(/images/dark-theme-background.png)'
                : undefined,
            backgroundColor:
              theme.palette.mode === 'dark' ? '#051521' : undefined,
            backgroundRepeat:
              theme.palette.mode === 'dark' ? 'no-repeat' : undefined,
            backgroundAttachment:
              theme.palette.mode === 'dark' ? 'fixed' : undefined,
            backgroundSize: theme.palette.mode === 'dark' ? 'cover' : undefined,
            minHeight: theme.palette.mode === 'dark' ? '100%' : undefined,
            backgroundPosition:
              theme.palette.mode === 'dark' ? 'top' : undefined,
          },
          '::-webkit-scrollbar': {
            width: '12px',
            height: '12px',
          },
          '::-webkit-scrollbar-track': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? '#272544'
                : theme.palette.grey[300],
            border: '2px solid transparent',
            borderRadius: '8px',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#6D9D80',
            border: '4px solid transparent',
            borderRadius: '8px',
            backgroundClip: 'padding-box',
            '&:hover': {
              backgroundColor:
                theme.palette.mode === 'dark' ? '#5DBC8D' : '#40996D',
            },
          },

          'body::-webkit-scrollbar': {
            width: '15px',
          },
          '@media print': {
            '*': {
              WebkitPrintColorAdjust: 'exact',
              printColorAdjust: 'exact',
              colorAdjust: 'exact',
            },
          },
          '@keyframes animate': {
            '0%': {
              top: '-1px',
              left: '-2px',
            },
            '50%': {
              top: '-1px',
              left: 'calc(100% - 150px)',
            },
            '100%': {
              top: '-1px',
              left: '-2px',
            },
          },
        }),
      },

      MuiAutocomplete: {
        defaultProps: {
          size: 'small',
          ChipProps: {
            color: 'primary',
          },
        },
        styleOverrides: {
          inputRoot: {
            backgroundColor:
              themeBasics.palette.mode === 'dark' ? '#091521' : '#FFFFFF',
          },
          root: {
            '& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
              color: themeBasics.palette.primary.main,
            },
          },
        },
      },

      MuiChip: {
        styleOverrides: {
          root: {
            color: themeBasics.palette.mode === 'dark' ? 'white' : 'black',
          },
        },
      },

      MuiTypography: {
        styleOverrides: {
          root: {
            display: 'flex !important',
            justifyContent: 'space-between',
            fontSize: '0.875rem',
          },
        },
        variants: [
          {
            props: { variant: 'h1' },
            style: {
              fontFamily: brandonFontFamily,
              fontSize: '20px',
              textTransform: 'uppercase',
              marginBottom: '8px',
              color: themeBasics.palette.mode === 'dark' ? 'white' : 'black',
            },
          },
          {
            props: { variant: 'h3' },
            style: {
              fontFamily: brandonFontFamily,
              fontSize: '14px',
              textTransform: 'uppercase',
              marginBottom: '8px',
              color: themeBasics.palette.mode === 'dark' ? 'white' : 'black',
            },
          },
        ],
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '12px',
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          root: {
            background:
              themeBasics.palette.mode === 'dark' ? '#091521' : 'white',
          },
        },
      },

      MuiInputLabel: {
        defaultProps: {},
        styleOverrides: {
          root: {
            color:
              themeBasics.palette.mode === 'dark'
                ? 'white !important'
                : 'initial',
          },
        },
      },

      MuiFilledInput: {
        styleOverrides: {
          root: {
            '&': {
              background: 'transparent !important',
            },
            '&:hover': {
              background: 'transparent !important',
            },
            '& .MuiInputBase-input': {
              background: 'transparent !important',
            },
          },
          input: {
            'body.dark &:-webkit-autofill': {
              WebkitBoxShadow: 'none !important',
              transition: 'background-color 5000s ease-in-out 0s !important',
            },
          },
        },
      },

      MuiAlert: {
        styleOverrides: {
          root: {
            '&.MuiAlert-filledWarning': {
              background: 'white',
              color: 'black',
              borderRadius: 0,
              padding: '0px 6px',
              letterSpacing: 0,
              '.MuiAlert-icon': {
                marginRight: '5px',
                padding: '5px 0',
              },
              '.MuiAlert-message': {
                padding: '6px 0',
              },
            },
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          track: {
            background: '#00CB8E',
            border: 'none',
          },
          thumb: {
            border: '3.5px solid #00CB8E',
            borderRadius: '50%',
            background: 'white',
            boxShadow: 'none !important',
            ':before': {
              boxShadow: 'none',
            },
          },
          valueLabel: {
            border: 'none',
            color: themeBasics.palette.mode === 'light' ? '#262626' : 'white',
            padding: '6px 14px 0 14px',
            fontSize: '14px',
            fontWeight: '400',
            background: 'none',
          },
          rail: {
            background: '#D9D9D9 !important',
            borderRadius: '3px',
            boxShadow: 'inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB',
            opacity: '1',
          },
        },
      },

      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontFamily: brandonFontFamily,
            textTransform: 'uppercase',
          },
        },
      },

      MuiPaginationItem: {
        styleOverrides: {
          root: {
            background:
              themeBasics.palette.mode === 'dark' ? 'white' : '#F5F7F9',
            color: 'black',
            '&:hover': {
              background: '#F5F7F9',
            },
            '&.Mui-selected': {
              background: themeBasics.palette.primary.main,
              color: '#F5F7F9',
              '&:hover': {
                background: themeBasics.palette.primary.main,
              },
            },
          },
        },
      },
    },

    MuiDialogTitle: {
      defaultProps: {
        className: 'font-heading',
      },
    },

    MuiDialog: {
      styleOverrides: {
        container: {
          backgroundColor: '#FFFFFF60',
          // needs to be white overlay
        },
        paper: {
          // backgroundColor: '#1E364F',
          // backgroundColor: '#051521',The same colour as Cork but it looks a
          // bit naff with MUI components ... Opting for a lighter one.
          backgroundImage: 'none',
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        spacing: themeBasics.spacing(2),
        root: {
          justifyContent: 'flex-start',
          paddingLeft: themeBasics.spacing(4),
          '& > *': {
            margin: themeBasics.spacing(2),
          },
        },
      },
    },
  } as ThemeOptions);
};

const getMainTheme = (clubColor?: string) => {
  const clubBasedColors = getDeploymentTargetBasedColors();

  const themeBasics = createTheme({
    breakpoints,
    typography: mainTypography,
    spacing,
    palette: { mode: 'light', ...clubBasedColors },
  });

  return createTheme(themeBasics, {
    components: {
      MuiButton: {
        defaultProps: {
          size: 'small',
          variant: 'primary',
        },
        styleOverrides: {
          root: {
            '.MuiButtonBase-root, &.Mui-disabled': {
              pointerEvents: 'all',
            },
          },
        },
        variants: [
          {
            props: { variant: 'primary' },
            style: {
              color: themeBasics.palette.button.primaryText,
              backgroundColor: themeBasics.palette.button.primary,
              fontFamily: mainThemeMediumFontFamily,
              textTransform: 'none',
              borderRadius: 32,
              fontSize: 16,
              lineHeight: 1,
              transition: 'all 0.3s ease-out',
              padding: '8px 16px',
              height: 40,
              '&:hover': {
                background: themeBasics.palette.button.primary,
                color: themeBasics.palette.button.primaryText,
                opacity: 0.7,
              },
              '&:disabled': {
                cursor: 'not-allowed',
                color: themeBasics.palette.button.primaryText,
                opacity: 0.7,
              },
            },
          },
          {
            props: { variant: 'primary', size: 'small' },
            style: {
              padding: '8px 16px',
              height: 40,
            },
          },
          {
            props: { variant: 'primary', size: 'large' },
            style: {
              padding: '16px',
              width: '224px',
              height: 56,
              '&:disabled': {
                color: themeBasics.palette.button.primaryText,
                backgroundColor: themeBasics.palette.button.primary,
              },
            },
          },

          {
            props: { variant: 'secondary' },
            style: {
              color: themeBasics.palette.button.primary,
              backgroundColor: 'transparent',
              fontFamily: mainThemeMediumFontFamily,
              textTransform: 'none',
              border: `1px solid ${themeBasics.palette.button.primary}`,
              borderRadius: 32,
              fontSize: 16,
              lineHeight: 1,
              transition: 'all 0.3s ease-out',
              padding: '8px 16px',
              height: 40,
              '&:hover': {
                background: themeBasics.palette.button.primary,
                color: themeBasics.palette.button.primaryText,
              },
              '&:disabled': {
                cursor: 'not-allowed',
                color: themeBasics.palette.button.primary,
                backgroundColor: 'transparent',
                opacity: 0.7,
              },
            },
          },
          {
            props: { variant: 'secondary', size: 'small' },
            style: {
              padding: '8px 16px',
              height: 40,
            },
          },
          {
            props: { variant: 'secondary', size: 'large' },
            style: {
              width: '224px',
              padding: '16px',
              height: 56,
            },
          },
          {
            props: { variant: 'error' },
            style: {
              color: 'white',
              backgroundColor: '#DC3545',
              fontFamily: mainThemeMediumFontFamily,
              textTransform: 'none',
              border: '1px solid #DC3545',
              borderRadius: 32,
              fontSize: 16,
              lineHeight: 1,
              transition: 'all 0.3s ease-out',
              padding: '8px 16px',
              height: 40,
              '&:hover': {
                color: 'white',
                backgroundColor: '#DC3545',
                opacity: 0.7,
              },
              '&:disabled': {
                cursor: 'not-allowed',
                color: 'white',
                backgroundColor: '#DC3545',
                opacity: 0.7,
              },
            },
          },
          {
            props: { variant: 'error', size: 'small' },
            style: {
              padding: '8px 16px',
              height: 40,
            },
          },
          {
            props: { variant: 'error', size: 'large' },
            style: {
              width: '224px',
              padding: '16px',
              height: 56,
            },
          },
        ],
      },

      MuiStep: {
        styleOverrides: {
          root: {
            '& .MuiStepLabel-label': {
              color: themeBasics.palette.mode === 'dark' ? 'white' : 'black',
            },
          },
        },
      },

      MuiRadio: {
        styleOverrides: {
          root: {
            '& .Mui-checked svg': {
              color: themeBasics.palette.primary.main + '!important',
            },
            '& .MuiSvgIcon-root': {
              width: '20px',
              height: '20px',
            },
            color: '#DCDCDC',
          },
        },
      },

      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            color: 'black',
            '& .MuiTypography-root': {
              fontSize: '14px',
            },
          },
        },
      },

      MuiAvatar: {
        styleOverrides: {
          root: {
            height: '48px',
            width: '48px',
            'svg.MuiAvatar-fallback': {
              width: '100%',
              height: '100%',
              background: `url(${DefaultProfileImage.src}) center no-repeat`,
              backgroundSize: '100% 100%',
            },
            'svg.MuiAvatar-fallback path': {
              display: 'none',
            },
          },
        },
      },

      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 40,
            height: 24,
            padding: 0,
            marginLeft: 8,
            marginRight: 8,
            '& .MuiSwitch-switchBase': {
              padding: 0,
              margin: 2,
              transitionDuration: '300ms',
              '.MuiTouchRipple-child': {
                backgroundColor: '#808080',
              },
              '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#FFF',
                '& + .MuiSwitch-track': {
                  backgroundColor:
                    themeBasics.palette.mode === 'dark'
                      ? '#2ECA45'
                      : themeBasics.palette.primary.main,
                  opacity: 1,
                  border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: 0.5,
                },
              },
              '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33CF4D',
                border: '6px solid #fff',
              },
              '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                  themeBasics.palette.mode === 'light'
                    ? themeBasics.palette.grey[100]
                    : themeBasics.palette.grey[600],
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: themeBasics.palette.mode === 'light' ? 0.7 : 0.3,
              },
            },
            '& .MuiSwitch-thumb': {
              boxShadow: 'none',
              boxSizing: 'border-box',
              width: 20,
              height: 20,
            },
            '& .MuiSwitch-track': {
              borderRadius: 24 / 2,
              backgroundColor: '#BBBBBB',
              opacity: 1,
              transition: themeBasics.transitions.create(['background-color'], {
                duration: 500,
              }),
            },
          },
        },
      },

      MuiPaper: {
        styleOverrides: {
          root: {
            '& .Mui-disabled': {
              // TODO: remove my important when Zak removes
              // body.dark important statement
              color: `${themeBasics.palette.grey[600]} !important`,
            },
          },
        },
      },

      MuiCssBaseline: {
        styleOverrides: (theme) => ({
          body: {
            backgroundColor: clubColor ?? 'black',
            color:
              theme.palette.mode === 'dark'
                ? 'white'
                : theme.palette.typography.secondary,
            margin: 0,
            fontFamily: `${mainThemeRegularFontFamily} !important`,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: 1.5,
            display: 'block',
            // backgroundImage:
            //   theme.palette.mode === 'dark'
            //     ? 'url(/images/dark-theme-background.png)'
            //     : undefined,
            // backgroundColor:
            //   theme.palette.mode === 'dark' ? '#051521' : undefined,
            // backgroundRepeat:
            //   theme.palette.mode === 'dark' ? 'no-repeat' : undefined,
            // backgroundAttachment:
            //   theme.palette.mode === 'dark' ? 'fixed' : undefined,
            // backgroundSize: theme.palette.mode === 'dark' ? 'cover' : undefined,
            // minHeight: theme.palette.mode === 'dark' ? '100%' : undefined,
          },
          '::-webkit-scrollbar': {
            width: '12px',
            height: '12px',
          },
          '::-webkit-scrollbar-track': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? '#272544'
                : theme.palette.grey[300],
            border: '2px solid transparent',
            borderRadius: '8px',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#6D9D80',
            border: '4px solid transparent',
            borderRadius: '8px',
            backgroundClip: 'padding-box',
            '&:hover': {
              backgroundColor:
                theme.palette.mode === 'dark' ? '#5DBC8D' : '#40996D',
            },
          },

          'body::-webkit-scrollbar': {
            width: '15px',
          },
          '@media print': {
            '*': {
              WebkitPrintColorAdjust: 'exact',
              printColorAdjust: 'exact',
              colorAdjust: 'exact',
            },
          },
          '@keyframes animate': {
            '0%': {
              top: '-1px',
              left: '-2px',
            },
            '50%': {
              top: '-1px',
              left: 'calc(100% - 150px)',
            },
            '100%': {
              top: '-1px',
              left: '-2px',
            },
          },
        }),
      },

      MuiAutocomplete: {
        defaultProps: {
          size: 'small',
          ChipProps: {
            color: 'primary',
          },
          popupIcon: <KeyboardArrowDownIcon />,
        },
        styleOverrides: {
          inputRoot: {
            backgroundColor:
              themeBasics.palette.mode === 'dark' ? '#091521' : '#FFFFFF',
          },
          root: {
            '& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
              color: themeBasics.palette.primary.main,
            },
            border: 'none',
            '& .MuiOutlinedInput-root': {
              border: 'none',
              borderRadius: '8px',
              height: 'auto',
              padding: '2px 4px !important',
              paddingRight: '32px !important',
              '&.Mui-disabled': {
                opacity: 0.38,
                cursor: 'not-allowed',
              },
            },
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiSvgIcon-root': {
              color: 'black !important',
            },
            '& .MuiInputBase-input': {
              '&:disabled': {
                opacity: 0.38,
                cursor: 'not-allowed',
              },
            },
          },
        },
      },

      MuiSelect: {
        defaultProps: {
          IconComponent: KeyboardArrowDownIcon,
        },
        styleOverrides: {
          root: {
            backgroundColor: '#F6F6F6',
            borderRadius: '8px',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiSvgIcon-root': {
              color: 'black !important',
            },
          },
          select: {
            padding: 8,
          },
        },
      },

      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: themeBasics.palette.colorVariants.light,
            color: themeBasics.palette.mode === 'dark' ? 'white' : 'black',
            borderRadius: '8px',
            '& .MuiChip-label': {
              padding: '4px 8px',
            },
          },
        },
      },

      MuiTypography: {
        styleOverrides: {
          root: {
            display: 'flex !important',
            justifyContent: 'space-between',
            fontSize: '0.875rem',
          },
        },
        variants: [
          {
            props: { variant: 'h1' },
            style: {
              fontFamily: mainThemeMediumFontFamily,
              fontSize: '40px',
              textTransform: 'capitalize',
              marginBottom: '8px',
              color: themeBasics.palette.mode === 'dark' ? 'white' : 'black',
            },
          },
          {
            props: { variant: 'h2' },
            style: {
              fontFamily: mainThemeBoldFontFamily,
              fontSize: '22px',
              textTransform: 'capitalize',
              marginBottom: '8px',
              color: themeBasics.palette.mode === 'dark' ? 'white' : 'black',
            },
          },
          {
            props: { variant: 'h3' },
            style: {
              fontFamily: mainThemeBoldFontFamily,
              fontSize: '14px',
              textTransform: 'capitalize',
              marginBottom: '8px',
              color: themeBasics.palette.mode === 'dark' ? 'white' : 'black',
            },
          },
        ],
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '12px',
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          root: {
            background:
              themeBasics.palette.mode === 'dark' ? '#091521' : 'white',
          },
        },
      },

      MuiInputLabel: {
        defaultProps: {},
        styleOverrides: {
          root: {
            color:
              themeBasics.palette.mode === 'dark'
                ? 'white !important'
                : 'initial',
          },
        },
      },

      MuiFilledInput: {
        styleOverrides: {
          root: {
            '&': {
              background: 'transparent !important',
            },
            '&:hover': {
              background: 'transparent !important',
            },
            '& .MuiInputBase-input': {
              background: 'transparent !important',
            },
          },
          input: {
            'body.dark &:-webkit-autofill': {
              WebkitBoxShadow: 'none !important',
              transition: 'background-color 5000s ease-in-out 0s !important',
            },
          },
        },
      },

      MuiAlert: {
        styleOverrides: {
          root: {
            '&.MuiAlert-filledWarning': {
              background: 'white',
              color: 'black',
              borderRadius: 0,
              padding: '0px 6px',
              letterSpacing: 0,
              '.MuiAlert-icon': {
                marginRight: '5px',
                padding: '5px 0',
              },
              '.MuiAlert-message': {
                padding: '6px 0',
              },
            },
          },
        },
      },

      MuiSlider: {
        styleOverrides: {
          root: {
            height: 0,
            padding: '12px 0 0',
          },
          track: {
            background: themeBasics.palette.primary.main,
            border: 'none',
            height: 2,
          },
          thumb: {
            background: themeBasics.palette.primary.main,
            borderColor: themeBasics.palette.primary.main,
            width: 16,
            height: 16,
            borderRadius: '50%',
            boxShadow: 'none !important',
            ':before': {
              boxShadow: 'none',
            },
          },
          valueLabel: {
            fontSize: 14,
            fontWeight: 500,
            fontFamily: mainThemeMediumFontFamily,
            border: 'none',
            color: 'black',
            padding: '8px 14px 0 14px',
            background: 'none',
          },
          rail: {
            height: 2,
            background: '#D9D9D9 !important',
            boxShadow: 'inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB',
            opacity: '1',
          },
        },
      },

      MuiPaginationItem: {
        styleOverrides: {
          root: {
            background: themeBasics.palette.pagination.background.default,
            color: themeBasics.palette.pagination.text,
            '&:hover': {
              background: themeBasics.palette.pagination.background.hover,
            },
            '&.Mui-selected': {
              background: themeBasics.palette.pagination.background.selected,
              color: themeBasics.palette.pagination.text,
              '&:hover': {
                background: themeBasics.palette.pagination.background.selected,
              },
            },
          },
        },
      },

      MuiTableContainer: {
        styleOverrides: {
          root: {
            background: 'white',
            borderRadius: 20,
          },
        },
      },

      MuiTableHead: {
        styleOverrides: {
          root: {
            fontFamily: mainThemeMediumFontFamily,
            textTransform: 'uppercase',
            fontSize: '14px',
            color: themeBasics.palette.table.headerText,
            background: themeBasics.palette.table.headerBackground,
          },
        },
      },

      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:nth-of-type(even)': {
              background: '#F6F6F6',
            },
          },
          cell: {
            color: 'white',
          },
        },
      },

      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: 'inherit',
            fontSize: '14px',
            fontWeight: 500,
            color: 'inherit',
            border: 'none',
            padding: 'inherit',
          },
          head: {
            padding: 8,
          },
          body: {
            padding: 12,
            color: 'black',
          },
        },
      },

      MuiTextField: {
        variants: [
          {
            props: {
              multiline: true,
            },
            style: {
              '& .MuiOutlinedInput-root': {
                height: 'auto',
                '& fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
              },
            },
          },
        ],
        styleOverrides: {
          spacing: themeBasics.spacing(2),
          root: {
            width: '100%',
            borderRadius: '8px',
            '& legend': {
              display: 'none',
            },
            '& fieldset': {
              top: 0,
            },
            '& label': {
              display: 'none',
            },
            '& label.Mui-focused': {
              display: 'none',
            },
            '& .MuiInput-underline:after': {
              border: 'none',
            },
            '& .MuiOutlinedInput-root': {
              height: '33px',
              '& fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
            },
          },
        },
      },

      MuiTabs: {
        variants: [
          {
            props: {
              orientation: 'vertical',
            },
            style: {
              '& .MuiTab-root': {
                py: '8px',
                px: '16px',
                borderRadius: '32px',
                minHeight: '36px',
                fontSize: '16px',
                color: 'black',
                fontFamily: mainThemeBoldFontFamily,
                textTransform: 'capitalize',
                '&.Mui-selected': {
                  backgroundColor: '#24D86C',
                  color: 'black',
                },
              },
            },
          },
          {
            props: {
              orientation: 'horizontal',
            },
            style: {
              '& .MuiTab-root': {
                py: '8px',
                px: '16px',
                borderRadius: '32px',
                minHeight: '36px',
                fontSize: '16px',
                color: 'black',
                fontFamily: mainThemeBoldFontFamily,
                backgroundColor: 'white',
                textTransform: 'capitalize',
                '&.Mui-selected': {
                  backgroundColor: '#24D86C',
                  color: 'black',
                },
              },
            },
          },
        ],
        styleOverrides: {
          indicator: {
            display: 'none',
          },
        },
      },

      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: '#DCDCDC',
            margin: 2,
            '&.Mui-disabled': {
              color: '#DCDCDC',
            },
            '&.Mui-checked': {
              '& svg path': {
                // eslint-disable-next-line id-length
                d: "path('M19 0h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z')",
              },
            },
            '&:not(.Mui-checked)': {
              '& svg path': {
                // eslint-disable-next-line id-length
                d: "path('M5 2c-1.654 0-3 1.346-3 3v14c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-14c0-1.654-1.346-3-3-3h-14zm19 3v14c0 2.761-2.238 5-5 5h-14c-2.762 0-5-2.239-5-5v-14c0-2.761 2.238-5 5-5h14c2.762 0 5 2.239 5 5z')",
              },
            },
            '& .MuiSvgIcon-root': {
              width: '16px',
              fontSize: '16px',
            },
          },
        },
      },

      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 24,
            '& .MuiSvgIcon-root': {
              color: 'black',
            },
          },
        },
      },

      MuiDialogTitle: {
        defaultProps: {
          className: 'font-heading',
        },
        styleOverrides: {
          root: {
            fontWeight: 300,
            fontSize: 22,
            fontFamily: mainThemeBoldFontFamily,
          },
        },
      },
    },
  } as ThemeOptions);
};

export const getTheme = (
  mode: ThemeMode,
  pathname: string,
  clubColor?: string
) => {
  if (getPerformanceLabPagePaths().includes(pathname)) {
    return getPerformanceLabTheme(mode);
  } else {
    return getMainTheme(clubColor);
  }
};
