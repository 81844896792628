import { Box, NoSsr, Stack, Typography } from '@mui/material';
import Image from 'next/image';

const MaintenanceMode = () => (
  <NoSsr>
    <Stack sx={customStyles.mainBox} spacing={8}>
      <Typography variant="h2">We&apos;ll be back soon!</Typography>

      <Box sx={customStyles.shortText}>
        <Typography>
          Our site is currently undergoing scheduled maintenance.
        </Typography>
        <Typography>
          We&apos;re working hard to improve your experience and will be back
          online shortly.
        </Typography>
      </Box>

      <Image
        src="/images/ai-io-logo.png"
        width={300}
        height={133}
        alt="ai io image"
        priority={true}
      />
    </Stack>
  </NoSsr>
);

export default MaintenanceMode;

const customStyles = {
  mainBox: {
    backgroundColor: 'white',
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    px: 10,
    py: 5,
    textAlign: 'center',
  },
  shortText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
