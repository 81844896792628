import { defaultColors } from './colors';

type ColorOverrides = {
  [key: string]: Partial<typeof defaultColors>;
};

type Colors = {
  primary: string;
  secondary: string;
  light: string;
  dark: string;
};

type DeploymentTargetBasedColors = {
  [key: string]: Colors;
};

const deploymentTargetBasedColors: DeploymentTargetBasedColors = {
  oci: {
    primary: '#773DBD',
    secondary: '#323E48',
    light: '#6FCFEB',
    dark: '#4E3176',
  },
  default: {
    primary: '#24D86C',
    secondary: '#FFFFFF',
    light: '#BDF3D3',
    dark: '#168241',
  },
};

export const colorOverrides: ColorOverrides = {
  oci: {
    button: {
      primary: deploymentTargetBasedColors.oci.primary,
      primaryText: 'white',
    },
    table: {
      headerBackground: deploymentTargetBasedColors.oci.secondary,
      headerText: 'white',
    },
    primary: {
      main: deploymentTargetBasedColors.oci.primary,
    },
    background: { default: deploymentTargetBasedColors.oci.primary },
    scatterChart: {
      primary: '#DCDCDC',
      secondary: 'black',
      point: deploymentTargetBasedColors.oci.primary,
    },
    pagination: {
      background: {
        default: deploymentTargetBasedColors.oci.light,
        hover: deploymentTargetBasedColors.oci.primary,
        selected: deploymentTargetBasedColors.oci.primary,
      },
      text: 'white',
    },
    colorVariants: {
      light: deploymentTargetBasedColors.oci.light,
      dark: deploymentTargetBasedColors.oci.dark,
    },
  },
};
