import { createEnv } from '@t3-oss/env-nextjs';
import { vercel } from '@t3-oss/env-nextjs/presets';
import ansis from 'ansis';
import { z } from 'zod';

export const env = createEnv({
  extends: [vercel()],
  server: {
    /**
     * This is the NODE Env set by the framework.
     */
    NODE_ENV: z
      .union(
        [z.literal('production'), z.literal('development'), z.literal('test')],
        {
          required_error:
            'NODE_ENV is required and should be either "production", "test" or "development"',
        }
      )
      .optional(),

    /**
     * This is the Connection string used for the New App Database
     */
    DATABASE_URL_APP: z.string().optional(), // TODO: Remove optional() once CI/CD Deployment in place.

    /**
     * This is the Luzmo API Key
     */
    LUZMO_API_KEY: z.string(),
    /**
     * This is the Luzmo API Token
     */
    LUZMO_API_TOKEN: z.string(),
    /**
     * This is slack webhook endpoint to notify errors
     */
    SLACK_ERROR_LOG_NOTIFICATION_WEBHOOK: z.string().url(),
    /**
     * The name of the project. This is used when speaking about the project in
     * emails and as the default value for other ID's. It should be a human
     * readable string but not have any crazy characters in. Spaces are OK and
     * will be replaced in ID's with -'s.
     */
    PROJECT_NAME: z.string(),
    /**
     * The name to use for the Application when registering endpoints with Inngest.
     */
    INNGEST_APP_NAME: z
      .string()
      .default(
        process.env.PROJECT_NAME?.replaceAll(' ', '-').toLowerCase() ?? 'App'
      ),

    /**
     * The AWS Access Key used to access Amazon SES
     */
    AWS_SES_ACCESS_KEY_ID:
      process.env.NODE_ENV === 'production' &&
      (!process.env.NEXT_PUBLIC_DEPLOYMENT_TARGET ||
        process.env.NEXT_PUBLIC_DEPLOYMENT_TARGET === 'AWS')
        ? z.string()
        : z.string().optional(),
    /**
     * The AWS Secret Access Key used to access Amazon SES
     */
    AWS_SES_SECRET_ACCESS_KEY:
      process.env.NODE_ENV === 'production' &&
      (!process.env.NEXT_PUBLIC_DEPLOYMENT_TARGET ||
        process.env.NEXT_PUBLIC_DEPLOYMENT_TARGET === 'AWS')
        ? z.string()
        : z.string().optional(),
    /**
     * The AWS Region to use for Amazon SES
     */
    AWS_SES_REGION:
      process.env.NODE_ENV === 'production' &&
      (!process.env.NEXT_PUBLIC_DEPLOYMENT_TARGET ||
        process.env.NEXT_PUBLIC_DEPLOYMENT_TARGET === 'AWS')
        ? z.string()
        : z.string().optional(),

    /**
     * The SMTP Host to use for sending emails
     */
    SMTP_HOST:
      process.env.NODE_ENV === 'production' &&
      process.env.NEXT_PUBLIC_DEPLOYMENT_TARGET &&
      process.env.NEXT_PUBLIC_DEPLOYMENT_TARGET === 'OCI'
        ? z.string()
        : z.string().optional(),
    /**
     * The SMTP Port to use for sending emails
     * @default 587
     */
    SMTP_PORT: z.coerce.number().default(587),
    /**
     * The SMTP Username to use for sending emails
     */
    SMTP_USERNAME:
      process.env.NODE_ENV === 'production' &&
      process.env.NEXT_PUBLIC_DEPLOYMENT_TARGET &&
      process.env.NEXT_PUBLIC_DEPLOYMENT_TARGET === 'OCI'
        ? z.string()
        : z.string().optional(),
    /**
     * The SMTP Password to use for sending emails
     */
    SMTP_PASSWORD:
      process.env.NODE_ENV === 'production' &&
      process.env.NEXT_PUBLIC_DEPLOYMENT_TARGET &&
      process.env.NEXT_PUBLIC_DEPLOYMENT_TARGET === 'OCI'
        ? z.string()
        : z.string().optional(),

    /**
     * The address to make emails come from.
     */
    EMAIL_FROM: z.string(),

    /**
     * The secret used for encrypting JWT's etc.
     *
     * generate using `npx auth secret`
     */
    AUTH_SECRET: z.string({
      required_error:
        ' AUTH_SECRET is required for casting etc. generate using `npx auth secret`',
    }),

    /**
     * The base URL to use for the Auth API's.
     *
     * NOTE: The Auth API Paths include the paths like `/api/auth` etc so this
     * should just be the base URL without a trailing /.
     *
     * @example https://controlcenter.ai.io
     */
    AUTH_URL: z.preprocess(
      // This makes Vercel deployments not fail if you don't set AUTH_URL
      // Since NextAuth.js automatically uses the VERCEL_URL if present.
      (str) => str ?? process.env.VERCEL_URL,
      // VERCEL_URL doesn't include `https` so it cant be validated as a URL
      process.env.VERCEL ? z.string() : z.string().url()
    ),
    /**
     * The name to show as the From of emails
     */
    EMAIL_FROM_NAME: z.string().default(process.env.PROJECT_NAME ?? 'App'),
    REFLEXION_API_KEY: z.string(),
    REFLEXION_BASE_URL: z.string().url(),
    OTEL_SERVICE_NAME: z.string(),
    /**
     * The PORT to use for the nextjs server.
     *
     * @see https://nextjs.org/docs/pages/api-reference/cli/next#changing-the-default-port
     */
    PORT: z.string().optional(),

    /**
     * The URL to the Keycloak server
     * @example https://keycloak.dev.api.aiscout.io/
     */
    KEYCLOAK_URL: z.string().url(),
    /**
     * The realm to use for Keycloak
     * @example aiscout
     */
    KEYCLOAK_REALM: z.string(),
    /**
     * The client ID to use for Keycloak
     * @example ControlCenter
     */
    KEYCLOAK_CLIENT_ID: z.string(),
    /**
     * The client Secret to use for Keycloak
     * @example TZr2lCcwFkRrUSyEtjFRbn4KePTc1pun
     */
    KEYCLOAK_CLIENT_SECRET: z.string(),
    KEYCLOAK_ADMIN_USERNAME: z.string(),
    KEYCLOAK_ADMIN_PASSWORD: z.string(),
    /**
     * Whether to enable instrumentation for Prisma
     * It can be pretty heavy so we might want to disable it in some cases.
     */
    PRISMA_INSTRUMENTATION_ENABLED: z
      .string()
      .transform((envVal) => envVal !== 'false' && envVal !== '0')
      .default('true'),

    /**
     * The Region used by the OIDC Provider (S3 ETC)
     */
    AWS_REGION: z.string(),
    /**
     * The Role Arn that should assumed through the Vercel OIDC Process
     */
    AWS_ROLE_ARN: z.string().optional(),

    /**
     * A cloudfront private key value to use for signing presigned URLs.
     *
     * If provided (both private key value & Public Key ID) Control Center won't call AWS SSM to get the keys.
     */
    AWS_CLOUDFRONT_PRIVATE_KEY_VALUE: z.string().optional(),
    /**
     * A cloudfront private key value to use for signing presigned URLs.
     *
     * If provided (both private key value & Public Key ID) Control Center won't call AWS SSM to get the keys.
     */
    AWS_CLOUDFRONT_PUBLIC_KEY_ID: z.string().optional(),
  },
  client: {
    /**
     * This is the absolute URL to the Cloudfront Domain used by the bernabeu
     * AiSCOUT App backend.
     */
    NEXT_PUBLIC_AISCOUT_CLOUDFRONT_DOMAIN: z.string(),
    /**
     * This is the public API key for the Google Maps API.
     * It's locked to the domains of the app.
     */
    NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: z.string(),
    /**
     * Environment we are running on
     */
    NEXT_PUBLIC_ENVIRONMENT: z.union([
      z.literal('production'),
      z.literal('staging'),
    ]),

    NEXT_PUBLIC_SLACK_TRIALIST_NOTIFICATION_WEBHOOK: z
      .string()
      .url({ message: 'Invalid url' }),

    NEXT_PUBLIC_REFLEXION_IFRAME_BASE_URL: z.string().url(),

    /**
     * This is the absolute URL including the path to use as the base when
     * making API Call to the Levi9 aiScout API's and will be set to the correct
     * value for the environment (prod / Staging)
     *
     * @example https://stage.api.aiscout.io/api
     */
    NEXT_PUBLIC_AISCOUT_API_BASE_URL: z
      .string()
      .url({ message: 'Invalid url' }),
    /**
     * This is the absolute URL without any path to use as the base when making API Calls to the
     * Levi9 aiScout API's and will be set to the correct value for the
     * environment (prod / Staging)
     *
     * This is the one to use with Orval!
     *
     * @example https://stage.api.aiscout.io
     */
    NEXT_PUBLIC_AISCOUT_API_BASE_URL_RAW: z
      .string()
      .url({ message: 'Invalid url' }),
    /**
     * This is the base URL to use for Assets and links to delphi pages.
     * It will be set to the correct value for each environment. (prod / Staging)
     */
    NEXT_PUBLIC_BASE_PATH_AISCOUT: z.string().url({ message: 'Invalid url' }),
    /**
     * This is the absolute URL to use as the base when making API Calls to do
     * Screen Casting functions and will be correct for production or staging.
     *
     * @example https://pl.aiscout.io
     */
    NEXT_PUBLIC_SCREEN_API_BASE_URL: z.string().url({ message: 'Invalid url' }),
    NEXT_PUBLIC_NODE_ENV: z.union(
      [z.literal('production'), z.literal('development'), z.literal('test')],
      {
        required_error:
          'NODE_ENV is required and should be either "production", "test" or "development"',
      }
    ),
    /* Temp env var to handle temporarily unavailable password rest emails in OCI */
    NEXT_PUBLIC_PASSWORD_RESET_EMAIL_AVAILABLE: z
      .string()
      .default('true')
      .transform((value) => value === 'true'),

    /* Env var to force proxying of aiscout API's */
    NEXT_PUBLIC_FORCE_AISCOUT_API_PROXY: z
      .string()
      .default('false')
      .transform((value) => value === 'true'),

    /* Temp env var to  handle when player app is not available */
    NEXT_PUBLIC_PLAYER_APP_AVAILABLE: z
      .string()
      .default('true')
      .transform((value) => value === 'true'),
    /**
     * The cloud target we are looking to deploy to for backend services.
     */
    NEXT_PUBLIC_DEPLOYMENT_TARGET: z
      .union([z.literal('AWS'), z.literal('OCI'), z.literal('LOCAL')])
      .default('AWS'),
    /**
     * App maintenance mode.
     */
    NEXT_PUBLIC_MAINTENANCE_MODE: z
      .string()
      .default('false')
      .transform((value) => value === 'true'),

    /**
     * The environment used by the aiscout Bernabeu infra and api's as part of
     * naming etc.
     *
     * For example this gets used in getting the cloudfront keys
     * /aiscout/${env.NEXT_PUBLIC_AISCOUT_ENV}/cloudfront/private_key_value
     */
    NEXT_PUBLIC_AISCOUT_ENV: z.union([
      z.literal('prod'),
      z.literal('stage'),
      z.literal('preprod'),
      z.literal('dev'),
    ]),
    /**
      The tenant ID for tenancy that the is deployed to.
     */
    NEXT_PUBLIC_TENANT_ID: z.string(),
    /**
      The URL of the sign up pages site.
     */
    NEXT_PUBLIC_SIGN_UP_PAGES_URL: z.string().url(),
  },
  experimental__runtimeEnv: {
    NEXT_PUBLIC_GOOGLE_MAPS_API_KEY:
      process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
    NEXT_PUBLIC_SLACK_TRIALIST_NOTIFICATION_WEBHOOK:
      process.env.NEXT_PUBLIC_SLACK_TRIALIST_NOTIFICATION_WEBHOOK,
    NEXT_PUBLIC_REFLEXION_IFRAME_BASE_URL:
      process.env.NEXT_PUBLIC_REFLEXION_IFRAME_BASE_URL,
    NEXT_PUBLIC_BASE_PATH_AISCOUT: process.env.NEXT_PUBLIC_BASE_PATH_AISCOUT,
    NEXT_PUBLIC_SCREEN_API_BASE_URL:
      process.env.NEXT_PUBLIC_SCREEN_API_BASE_URL,
    NEXT_PUBLIC_NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_AISCOUT_API_BASE_URL:
      process.env.NEXT_PUBLIC_AISCOUT_API_BASE_URL,
    NEXT_PUBLIC_AISCOUT_API_BASE_URL_RAW:
      process.env.NEXT_PUBLIC_AISCOUT_API_BASE_URL_RAW,
    NEXT_PUBLIC_PASSWORD_RESET_EMAIL_AVAILABLE:
      process.env.NEXT_PUBLIC_PASSWORD_RESET_EMAIL_AVAILABLE,
    NEXT_PUBLIC_FORCE_AISCOUT_API_PROXY:
      process.env.NEXT_PUBLIC_FORCE_AISCOUT_API_PROXY,
    NEXT_PUBLIC_PLAYER_APP_AVAILABLE:
      process.env.NEXT_PUBLIC_PLAYER_APP_AVAILABLE,
    NEXT_PUBLIC_DEPLOYMENT_TARGET: process.env.NEXT_PUBLIC_DEPLOYMENT_TARGET,
    NEXT_PUBLIC_MAINTENANCE_MODE: process.env.NEXT_PUBLIC_MAINTENANCE_MODE,
    NEXT_PUBLIC_AISCOUT_CLOUDFRONT_DOMAIN:
      process.env.NEXT_PUBLIC_AISCOUT_CLOUDFRONT_DOMAIN,
    NEXT_PUBLIC_AISCOUT_ENV: process.env.NEXT_PUBLIC_AISCOUT_ENV,
    NEXT_PUBLIC_TENANT_ID: process.env.NEXT_PUBLIC_TENANT_ID,
    NEXT_PUBLIC_SIGN_UP_PAGES_URL: process.env.NEXT_PUBLIC_SIGN_UP_PAGES_URL,
  },
  onValidationError: (error) => {
    // Need to check the error in SSR and Pre Rendering is fixed by removing the process.exit...
    console.error(
      ansis.red('error -'),
      '❌ Invalid environment variables:',
      error.flatten().fieldErrors
    );
  },
  onInvalidAccess: (variable) => {
    // Need to check the error in SSR and Pre Rendering is fixed by removing the process.exit...
    console.error(
      ansis.red('error -'),
      `❌ Attempted to access a server-side environment variable ${variable} on the client`
    );
  },
  skipValidation: process.env.SKIP_ENV_VALIDATION === 'true',
});
