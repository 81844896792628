import { PageLink } from './SidebarMenuJSON';

type ThemeCommonProps = Pick<PageLink, 'defaultTheme' | 'supportsBothThemes'>;
type SideBarCommonProps = Pick<PageLink, 'roles'> & ThemeCommonProps;

export const lightThemeCommonProps: ThemeCommonProps = {
  defaultTheme: 'light',
  supportsBothThemes: true,
};

export const darkThemeCommonProps: ThemeCommonProps = {
  defaultTheme: 'dark',
  supportsBothThemes: true,
};

export const futurePageCommonProps: Pick<PageLink, 'link' | 'roles'> = {
  link: '#',
  roles: ['9:AiSCOUTStaff'],
};

export const commercialAreaCommonProps: SideBarCommonProps = {
  roles: [
    '1:AiSCOUTAppointedPerson',
    '2:HeadOfRecruitment',
    '3:HeadScout',
    '5:LeadOrHeadCommercial',
    '6:CommercialStaff',
    '9:AiSCOUTStaff',
  ],
  ...lightThemeCommonProps,
};

export const recruitmentCenterCommonProps: SideBarCommonProps = {
  roles: [
    '1:AiSCOUTAppointedPerson',
    '2:HeadOfRecruitment',
    '3:HeadScout',
    '4:LocalOrRegionalScout',
    '9:AiSCOUTStaff',
    '10:Scout',
    '14:MLSNext',
    '15:RecruitmentCentre',
  ],
  ...lightThemeCommonProps,
};

export const academyAnalysisCommonProps: SideBarCommonProps = {
  roles: [
    '1:AiSCOUTAppointedPerson',
    '2:HeadOfRecruitment',
    '3:HeadScout',
    '7:HeadOfPlayerDevelopmentOrPerformanceOrSportScience',
    '9:AiSCOUTStaff',
    '14:MLSNext',
  ],
  ...lightThemeCommonProps,
};

export const scoutHubCommonProps: SideBarCommonProps = {
  roles: [
    '1:AiSCOUTAppointedPerson',
    '2:HeadOfRecruitment',
    '3:HeadScout',
    '4:LocalOrRegionalScout',
    '9:AiSCOUTStaff',
    '10:Scout',
  ],
  ...lightThemeCommonProps,
};

export const adminCommonProps: SideBarCommonProps = {
  roles: [
    '1:AiSCOUTAppointedPerson',
    '2:HeadOfRecruitment',
    '3:HeadScout',
    '9:AiSCOUTStaff',
  ],
  ...lightThemeCommonProps,
};

export const appContentCommonProps: SideBarCommonProps = {
  roles: ['9:AiSCOUTStaff'],
  ...lightThemeCommonProps,
};

export const turkCommonProps: SideBarCommonProps = {
  roles: ['9:AiSCOUTStaff', '11:Turk'],
  ...lightThemeCommonProps,
};
