import { ThemeProvider } from '@mui/material/styles';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { getTheme, ThemeMode } from 'components/MUI/theme';
import SidebarMenuJSON from 'components/shared/Sidebar/SidebarMenuJSON';
import { themeState, themeSwitchState } from 'state/atoms';
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect';
import { api } from 'helpers/trpc/pages';

interface Props {
  children: JSX.Element[] | JSX.Element;
}

const flattenedMenuLinks = SidebarMenuJSON.flatMap(({ submenu }) => submenu);

export const MUIThemeProvider = ({ children }: Props) => {
  const [theme, setTheme] = useRecoilState(themeState);
  const themeSwitched = useRecoilValue(themeSwitchState);
  const router = useRouter();

  const { data: currentUser } = api.shared.currentUser.useQuery();

  const currentPageLink = flattenedMenuLinks.find(
    ({ link }) => link === router.pathname
  );

  useEffect(() => {
    if (currentPageLink && currentPageLink.defaultTheme) {
      setTheme(currentPageLink.defaultTheme);
    } else {
      setTheme('light');
    }
  }, [currentPageLink, setTheme]);

  const currentMUITheme = themeSwitched
    ? getTheme(theme, router.pathname, currentUser?.club.color)
    : getTheme(
        currentPageLink?.defaultTheme ?? 'light',
        router.pathname,
        currentUser?.club.color
      );

  useIsomorphicLayoutEffect(() => {
    const changeTheme = (theme: ThemeMode) => {
      setTheme(theme);
      document.body.classList.add(theme);
      document.body.classList.remove(theme === 'light' ? 'dark' : 'light');
    };

    changeTheme(currentMUITheme.palette.mode);
  }, [currentPageLink]);

  return <ThemeProvider theme={currentMUITheme}>{children}</ThemeProvider>;
};
