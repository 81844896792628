import { Theme } from '@mui/material';
import { atom, atomFamily } from 'recoil';
import { urlSyncEffect } from 'recoil-sync';
import { z } from 'zod';
import { getRefineCheckerForZodSchema } from 'zod-refine';

import { metricModes } from 'components/pages/Discover/constants';
import { InferTRPCOutputTypes, RouterOutputs } from 'helpers/trpc';
import { PlayersFoot } from 'server/routers/shared/types';

export type AffiliationCode =
  InferTRPCOutputTypes['shared']['getAllAffiliationCodes']['affiliationCodes'][number];

export type ClubMetric =
  InferTRPCOutputTypes['recruitmentCenter']['getClubDrillMetrics']['drillMetrics'][number];

type TrialSuite =
  InferTRPCOutputTypes['recruitmentCenter']['getClubTrialSuites'][number];

export const pinSidebarState = atom({
  key: 'pinSidebarState',
  default: false,
});

export const castActiveState = atom({
  key: 'cast',
  default: false,
  effects: [
    urlSyncEffect({
      refine: getRefineCheckerForZodSchema(z.boolean()),
      history: 'push',
    }),
  ],
});

export type PixelCrop = {
  width: number;
  height: number;
  x: number;
  y: number;
};

export const defaultUploadImageState = {
  isOpen: false as boolean,
  zoom: 1 as number,
  crop: { x: 0, y: 0 },
  imageSrc: null as null | string,
  file: null as null | File,
  croppedAreaPixels: null as PixelCrop | null,
  croppedImage: null as string | null,
};

export type DefaultUploadImageStateType = typeof defaultUploadImageState;

export const uploadImageState = atom({
  key: 'uploadImageState',
  default: defaultUploadImageState,
});

export const uploadImageStateFamily = atomFamily<
  DefaultUploadImageStateType,
  string
>({
  key: 'uploadImageStateFamily',
  default: defaultUploadImageState,
});

export const notFoundErrorMessageState = atom({
  key: 'notFoundErrorMessageState',
  default: '',
  effects: [
    urlSyncEffect({
      refine: getRefineCheckerForZodSchema(z.string()),
      history: 'push',
      itemKey: 'errorMessage',
    }),
  ],
});

export const themeState = atom<Theme['palette']['mode']>({
  key: 'themeState',
  default: 'light',
});

export const defaultDiscoveryOptionState = {
  clubTrialMetricFirst: null as ClubMetric | null,
  clubTrialMetricSecond: null as ClubMetric | null,
  trialSuites: [] as TrialSuite[],
  clubTrialMetricFirstValue: 0,
  clubTrialMetricSecondValue: 0,
  discoveryGraph: 'PlayersGraph' as 'PlayersGraph' | 'ScoreDistribution',
  firstMetricModes: metricModes,
  secondMetricModes: metricModes,
};

export const discoveryOptionsState = atom({
  key: 'discoveryOptionsState',
  default: defaultDiscoveryOptionState,
});

export const slidersContraintsDefault = {
  age: { min: 4, max: 30 },
  height: { min: 0, max: 220 },
  weight: { min: 0, max: 90 },
  NRSScore: { min: 0, max: 2.14 },
  fathersHeight: { min: 0, max: 220 },
  mothersHeight: { min: 0, max: 220 },
};

export const imperialSliderConstraints = {
  ...slidersContraintsDefault,
  height: { min: 0, max: 87 },
  weight: { min: 0, max: 198 },
  fathersHeight: { min: 0, max: 87 },
  mothersHeight: { min: 0, max: 87 },
};

type SlidersContraintsDefault = typeof slidersContraintsDefault;

export const monthsList = [
  { value: 1, name: 'January' },
  { value: 2, name: 'February' },
  { value: 3, name: 'March' },
  { value: 4, name: 'April' },
  { value: 5, name: 'May' },
  { value: 6, name: 'June' },
  { value: 7, name: 'July' },
  { value: 8, name: 'August' },
  { value: 9, name: 'September' },
  { value: 10, name: 'October' },
  { value: 11, name: 'November' },
  { value: 12, name: 'December' },
];

type Month = typeof monthsList[number];

export const discoveryFiltersDefaultState = {
  playerName: '',
  affiliationCodes: [] as AffiliationCode[],
  slidersContraints: slidersContraintsDefault as SlidersContraintsDefault,
  ageSlider: [
    slidersContraintsDefault.age.min,
    slidersContraintsDefault.age.max,
  ],
  birthMonth: [] as Month[],
  birthYear: [] as number[],
  ageGroup: [] as RouterOutputs['shared']['getAllAgeGroups'],
  gender: 'All' as 'All' | 'Male' | 'Female',
  position: [] as RouterOutputs['shared']['getAllPositions'],
  dominantFoots: [] as PlayersFoot[],
  isAllAgesChecked: false,
  heightSlider: slidersContraintsDefault.height.min,
  weightSlider: slidersContraintsDefault.weight.min,
  NRSScoreSlider: slidersContraintsDefault.NRSScore.min,
  fathersHeightSlider: slidersContraintsDefault.fathersHeight.min,
  mothersHeightSlider: slidersContraintsDefault.mothersHeight.min,
  city: '',
  counties: [] as RouterOutputs['shared']['getCounties']['items'],
  countries: [] as RouterOutputs['shared']['getAllCountries'],
  nationalities: [] as RouterOutputs['shared']['getAllNationalities'],
  canPlayForCountries: [] as RouterOutputs['shared']['getAllCountries'],
  passportsHeld: [] as RouterOutputs['shared']['getAllPassportAuthorities'],
  ethnicities: [] as RouterOutputs['shared']['getAllEthnicities'],
  dateFrom: null as null | Date,
  dateTo: null as null | Date,
};

export const discoveryFiltersState = atom({
  key: 'discoveryFiltersState',
  default: discoveryFiltersDefaultState,
});

export const isFetchingFilteredDataState = atom({
  key: 'isFetchingFilteredDataState',
  default: true,
});

export const selectedBenchmarkNameState = atom({
  key: 'selectedBenchmarkNameState',
  default: '',
});

export const themeSwitchState = atom({
  key: 'themeSwitchState',
  default: false,
});

export const playerReportInProgressRouteState = atom<string | undefined>({
  key: 'playerReportInProgressRouteState',
  default: undefined,
});

export const matchReportInProgressRouteState = atom<string | undefined>({
  key: 'matchReportInProgressRouteState',
  default: undefined,
});

export const playerReportCompletedRouteState = atom<string | undefined>({
  key: 'playerReportCompletedRouteState',
  default: undefined,
});

export const matchReportCompletedRouteState = atom<string | undefined>({
  key: 'matchReportCompletedRouteState',
  default: undefined,
});
