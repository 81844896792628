import { env } from 'env.mjs';

const isProduction = env.NEXT_PUBLIC_ENVIRONMENT === 'production';
export const isLocalDev =
  env.NEXT_PUBLIC_NODE_ENV === 'development' &&
  env.NEXT_PUBLIC_ENVIRONMENT !== 'production';

export const AISCOUT_CLUB_ID = 92;
export const AISCOUT_CLUB_UUID = 'b40b4e6d-4d65-431c-8b60-b7dd42c02e6c';

export const CHELSEA_CLUB_ID = 24;

export const BURNLEY_CLUB_ID = 17;

export const MAHD_SPORTS_ACADEMY_ID = 99;
export const MAHD_SPORTS_ACADEMY_NAME = 'Mahd Sports Academy';

let mahdSportsAcademyUuid;
if (env.NEXT_PUBLIC_DEPLOYMENT_TARGET === 'OCI') {
  mahdSportsAcademyUuid = 'dc33b6ff-108f-4f05-bec1-56c4fd7cf082';
} else if (isProduction) {
  mahdSportsAcademyUuid = '5b1fb0a0-4451-49a4-af19-809616d850c7';
} else {
  mahdSportsAcademyUuid = '9b302a3b-444a-4191-a3bf-4c1719b33d22';
}

export const MAHD_SPORTS_ACADEMY_UUID = mahdSportsAcademyUuid;

export const MANCHESTER_UNITED_CLUB_UUID =
  '510b864f-1849-4edd-9e0a-45d78af7fa45'; // There is no stage Manchester United club id in the database

export const SENEGAL_CLUB_ID = isProduction ? 174 : 169;

export const MLS_CLUB_ID = isProduction ? 163 : 171;

export const ENGLAND_COUNTRY_ID = 1;

// DEV UUID IF NEEDED b67a589d-3959-4a18-8803-fd78d7f08909
export const ENGLAND_COUNTRY_UUID = isProduction
  ? 'c8acd85c-129f-43c2-81f6-afda34a95287'
  : 'b67a589d-3959-4a18-8803-fd78d7f08909';

export const PRO_CLUB_TYPE_ID_SIGNED = 2;

export const PRO_CLUB_TYPE_ID_TRIALING_AISCOUT = 'TRIAL';
export const PRO_CLUB_TYPE_ID_SIGNED_AISCOUT = 'SIGNED';

export const PRO_CLUB_TRIAL_CATEGORY_ID = isProduction
  ? '19dec11c-2e65-417d-ae8c-e9b7b1e6742f'
  : '758e4878-557b-498a-b708-e36230942092';

export const SENEGAL_DEFAULT_PROFILE_IMAGE_URL =
  'user/defaults/epl/club-badges/senegal';

export const errorCodes = {
  accessErrorCode: 403,
  notFoundErrorCode: 404,
  unknownErrorCode: 500,
};

export const errorMessages = {
  filterExists: 'Filter already available in the filters list',
  noFullAccessFilter: 'You cannot add multiple full access filters',
};

export const UK_SCHOOL_YEAR_SEASON_END_MONTH = 5; // May
export const UK_SCHOOL_YEAR_SEASON_START_MONTH = 8; // August
export const UK_SCHOOL_YEAR_SEASON_START_DAY = 31;

export const UK_SCHOOL_YEAR_TERM_START_MONTH = 9; // September
export const UK_SCHOOL_YEAR_TERM_START_DAY = 1;

export const GITEX_SESSION_ID = isProduction
  ? '510f439d-3c61-4323-8e09-172c65b5afa4'
  : 'c75b0f1b-30b3-4135-9fe2-6131963506d2';

export const AISCOUT_COUNTRY_NAME = 'England';
export const MAHD_SPORTS_ACADEMY_COUNTRY_NAME = 'Saudi Arabia';

export const defaultStringValue = '';
export const defaultNumberValue = 0;

export const DECISION_SECTION_ID = 'decision';
